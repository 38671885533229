/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@font-face {
    font-family: "IranSans";
    src: local("IranSans"), url(./assets/fonts/IRANSans.ttf) format("truetype");
  }
  
  @font-face {
    font-family: "POPPINS-REGULAR";
    src: local("POPPINS-REGULAR"),
        url(./assets/fonts/Poppins/POPPINS-REGULAR.TTF) format("truetype");
  }
  
  @font-face {
    font-family: "POPPINS-MEDIUM";
    src: local("POPPINS-MEDIUM"),
        url(./assets/fonts/Poppins/POPPINS-MEDIUM.TTF) format("truetype");
  }
  
  @font-face {
    font-family: "POPPINS-BOLD";
    src: local("POPPINS-BOLD"),
        url(./assets/fonts/Poppins/POPPINS-BOLD.TTF) format("truetype");
  }
  
  @font-face {
    font-family: "Amiri";
    src: url(./assets/fonts/Amiri-Regular.ttf);
  }
  
  @font-face {
    font-family: "digital-7";
    src: url(./assets/fonts/DIGITAL-7.ttf);
  }
  
  
  /*///////////////////////////////////////////////////////////////////*/
  
  @font-face {
    font-family: "vazirmatn-Black";
    src: url(./assets/fonts/vazir/Vazirmatn-Black.ttf);
  }
  
  @font-face {
    font-family: "vazirmatn-ExtraBold";
    src: url(./assets/fonts/vazir/Vazirmatn-ExtraBold.ttf);
  }
  
  @font-face {
    font-family: "vazirmatn-bold";
    src: url(./assets/fonts/vazir/Vazirmatn-Bold.ttf);
  }
  
  
  @font-face {
    font-family: "vazirmatn-SemiBold";
    src: url(./assets/fonts/vazir/Vazirmatn-SemiBold.ttf);
  }
  
  @font-face {
    font-family: "vazirmatn-medium";
    src: url(./assets/fonts/vazir/Vazirmatn-Medium.ttf);
  }
  
  @font-face {
    font-family: "vazirmatn-Regular";
    src: url(./assets/fonts/vazir/Vazirmatn-Regular.ttf);
  }
  
  
  @font-face {
    font-family: "vazirmatn-Light";
    src: url(./assets/fonts/vazir/Vazirmatn-Light.ttf);
  }
  
  @font-face {
    font-family: "vazirmatn-ExtraLight";
    src: url(./assets/fonts/vazir/Vazirmatn-ExtraLight.ttf);
  }
  
  @font-face {
    font-family: "vazirmatn-Thin";
    src: url(./assets/fonts/vazir/Vazirmatn-Thin.ttf);
  }
  
  
  /*///////////////////////////////////////////////////////////////////*/
  body {
    margin: 0;
    font-family: vazirmatn-medium, Amiri;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  
  
  
  
  li {
    font-family: vazirmatn-Light !important;
    font-size: 0.9rem;
    font-weight: 600;
  }
  
  input {
    font-family: vazirmatn-Regular !important;
    font-size: 0.9rem;
    font-weight: 400;
  }
  
  
  /*must span and label similar becuse in text Field both of them is used*/
  span,
  label {
    font-family: vazirmatn-Regular !important;
    font-size: 1.08rem;
    font-weight: 300;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block-end: 0;
    margin-block-start: 0;
  }
  ul{
    padding-inline-start: 0px;
  }
  
  @media screen and (min-width: 0px) {
  
    /*xs*/
    h1 {
        font-family: vazirmatn-ExtraBold !important;
        font-size: 2.7rem;
        line-height: 3rem;
        font-weight: 700;
        letter-spacing: -1.4px;
    }
  
    h2 {
        font-family: vazirmatn-bold;
        font-size: 1.8rem;
        line-height: 2.9rem;
        font-weight: 700;
        letter-spacing: -1.25px;
    }
  
    h3 {
        font-family: vazirmatn-SemiBold;
        font-size: 1.44rem;
        line-height: 1.6rem;
        font-weight: 700;
        letter-spacing: -1.25px;
        margin-block-end: 0;
        margin-block-start: 0;
    }
  
    h4 {
        font-family: vazirmatn-SemiBold;
        font-size: 1.35rem;
        line-height: 1.4rem;
        font-weight: 700;
        letter-spacing: -.05px;
  
    }
  
    h5 {
        font-family: vazirmatn-medium;
        font-size: 1.17rem;
        line-height: 1.21rem;
        font-weight: 700;
        letter-spacing: -.04px;
  
    }
  
    h6 {
        font-family: vazirmatn-medium;
        font-size: 0.99rem;
        line-height: 1.1rem;
        font-weight: 700;
        letter-spacing: -.0375px;
    }
  
    a {
        font-family: vazirmatn-medium;
        font-size: 0.81rem;
        line-height: 1.9rem;
        font-weight: 300;
        letter-spacing: -.16px;
  
    }
  
    td {
        font-family: vazirmatn-Regular;
        font-size: 0.76rem;
        line-height: 1.8rem;
        font-weight: 400;
        letter-spacing: -.14px;
        text-align: justify;
    }
  
    p {
        font-family: vazirmatn-Regular;
        font-size: 0.76rem;
        line-height: 1.8rem;
        font-weight: 400;
        letter-spacing: -.14px;
        text-align: justify;
        margin-block-end: 0;
        margin-block-start: 0;
    }
  
    div {
        font-family: vazirmatn-Light !important;
        font-size: 0.99rem !important;
        /*for button we must use important*/
        line-height: 1.1rem;
        font-weight: 500;
        letter-spacing: -.0087rem;
    }
  
    button {
        font-family: vazirmatn-Regular !important;
        font-size: 0.72rem !important;
        /*for button we must use important*/
        line-height: 0.9rem !important;
        /*for button we must use important*/
        font-weight: 500;
        letter-spacing: -.0087rem;
    }
  }
  
  @media screen and (min-width: 300px) {
  
    /*sm*/
    h1 {
        font-family: vazirmatn-ExtraBold !important;
        font-size: 2.88rem;
        line-height: 4rem;
        font-weight: 700;
        letter-spacing: -1.4px;
    }
  
    h2 {
        font-family: vazirmatn-bold;
        font-size: 1.89rem;
        line-height: 3rem;
        font-weight: 700;
        letter-spacing: -1.25px;
    }
  
    h3 {
        font-family: vazirmatn-SemiBold;
        font-size: 1.48rem;
        line-height: 1.6rem;
        font-weight: 700;
        letter-spacing: -1.25px;
    }
  
    h4 {
        font-family: vazirmatn-SemiBold;
        font-size: 1.35rem;
        line-height: 1.5rem;
        font-weight: 700;
        letter-spacing: -.05px;
  
    }
  
    h5 {
        font-family: vazirmatn-medium;
        font-size: 1.21rem;
        line-height: 1.4rem;
        font-weight: 700;
        letter-spacing: -.04px;
  
    }
  
    h6 {
        font-family: vazirmatn-medium;
        font-size: 1.08rem;
        line-height: 1.15rem;
        font-weight: 700;
        letter-spacing: -.0375px;
    }
  
    a {
        font-family: vazirmatn-medium;
        font-size: 0.9rem;
        line-height: 1.1rem;
        font-weight: 300;
        letter-spacing: -.16px;
  
    }
  
    td {
        font-family: vazirmatn-Regular;
        font-size: 0.82rem;
        line-height: 1rem;
        font-weight: 400;
        text-align: justify;
        letter-spacing: -.14px;
    }
  
    p {
        font-family: vazirmatn-Regular;
        font-size: 0.82rem;
        line-height: 1rem;
        font-weight: 400;
        text-align: justify;
        letter-spacing: -.14px;
        margin-block-end: 0;
        margin-block-start: 0;
    }
  
    div {
        font-family: vazirmatn-Light !important;
        font-size: 1.02rem !important;
        /*for button we must use important*/
        line-height: 1.2rem;
        font-weight: 500;
        letter-spacing: -.0087rem;
    }
  
    button {
        font-family: vazirmatn-Regular !important;
        font-size: 0.73rem !important;
        /*for button we must use important*/
        line-height: 1rem !important;
        /*for button we must use important*/
        font-weight: 500;
        letter-spacing: -.0087rem;
    }
  }
  
  @media screen and (min-width: 600px) {
  
    /*md*/
    h1 {
        font-family: vazirmatn-ExtraBold !important;
        font-size: 2.97rem;
        line-height: 3.7rem;
        font-weight: 700;
        letter-spacing: -1.4px;
    }
  
    h2 {
        font-family: vazirmatn-bold;
        font-size: 1.98rem;
        line-height: 2.6rem;
        font-weight: 700;
        letter-spacing: -1.25px;
    }
  
    h3 {
        font-family: vazirmatn-SemiBold;
        font-size: 1.53rem;
        line-height: 2.2rem;
        font-weight: 700;
        letter-spacing: -1.25px;
        margin-block-end: 0;
        margin-block-start: 0;
    }
  
    h4 {
        font-family: vazirmatn-SemiBold;
        font-size: 1.44rem;
        line-height: 2rem;
        font-weight: 700;
        letter-spacing: -.05px;
  
    }
  
    h5 {
        font-family: vazirmatn-medium;
        font-size: 1.35rem;
        line-height: 1.5rem;
        font-weight: 700;
        letter-spacing: -.04px;
  
    }
  
    h6 {
        font-family: vazirmatn-medium;
        font-size: 1.17rem;
        line-height: 1.3rem;
        font-weight: 700;
        letter-spacing: -.0375px;
    }
  
    a {
        font-family: vazirmatn-medium;
        font-size: 0.9rem;
        line-height: 1.1rem;
        font-weight: 300;
        letter-spacing: -.16px;
  
    }
  
    td {
        font-family: vazirmatn-Regular;
        font-size: 0.82rem;
        line-height: 1rem;
        font-weight: 400;
        text-align: justify;
        letter-spacing: -.14px;
    }
  
    p {
        font-family: vazirmatn-Regular;
        font-size: 0.82rem;
        line-height: 1rem;
        font-weight: 400;
        text-align: justify;
        letter-spacing: -.14px;
        margin-block-end: 0;
        margin-block-start: 0;
    }
  
    div {
        font-family: vazirmatn-Light !important;
        font-size: 1.08rem !important;
        /*for button we must use important*/
        line-height: 1.3rem;
        font-weight: 500;
        letter-spacing: -.0087rem;
    }
  
    button {
        font-family: vazirmatn-Regular !important;
        font-size: 0.76rem !important;
        /*for button we must use important*/
        line-height: 0.9rem !important;
        /*for button we must use important*/
        font-weight: 500;
        letter-spacing: -.0087rem;
    }
  
  }
  
  @media screen and (min-width: 900px) {
  
    /*lg*/
    h1 {
        font-family: vazirmatn-ExtraBold !important;
        font-size: 3rem;
        line-height: 4rem;
        font-weight: 700;
        letter-spacing: -1.4px;
    }
  
    h2 {
        font-family: vazirmatn-bold;
        font-size: 2rem;
        line-height: 3rem;
        font-weight: 700;
        letter-spacing: -1.25px;
    }
  
    h3 {
        font-family: vazirmatn-SemiBold;
        font-size: 1.6rem;
        line-height: 2.6rem;
        font-weight: 700;
        letter-spacing: -1.25px;
        margin-block-end: 0;
        margin-block-start: 0;
    }
  
    h4 {
        font-family: vazirmatn-SemiBold;
        font-size: 1.53rem;
        line-height: 2.5rem;
        font-weight: 700;
        letter-spacing: -.05px;
  
    }
  
    h5 {
        font-family: vazirmatn-medium;
        font-size: 1.3rem;
        line-height: 2.1rem;
        font-weight: 700;
        letter-spacing: -.04px;
  
    }
  
    h6 {
        font-family: vazirmatn-medium;
        font-size: 1.1rem;
        line-height: 2rem;
        font-weight: 700;
        letter-spacing: -.0375px;
    }
  
    a {
        font-family: vazirmatn-medium;
        font-size: 0.99rem;
        line-height: 1.9rem;
        font-weight: 300;
        letter-spacing: -.16px;
  
    }
  
    td {
        font-family: vazirmatn-Regular;
        font-size: 0.9rem;
        line-height: 1.7rem;
        font-weight: 400;
        text-align: justify;
        letter-spacing: -.14px;
    }
  
    p {
        font-family: vazirmatn-Regular;
        font-size: 0.9rem;
        line-height: 1.7rem;
        font-weight: 400;
        text-align: justify;
        letter-spacing: -.14px;
        margin-block-end: 0;
        margin-block-start: 0;
    }
  
    div {
        font-family: vazirmatn-Light !important;
        font-size: 1.08rem !important;
        /*for button we must use important*/
        line-height: 1.7rem;
        font-weight: 500;
        letter-spacing: -.0087rem;
    }
  
    button {
        font-family: vazirmatn-Light !important;
        font-size: 0.81rem !important;
        /*for button we must use important*/
        line-height: 1.7rem !important;
        /*for button we must use important*/
        font-weight: 500;
        letter-spacing: -0.01rem !important;
        /*for button we must use important*/
    }
  
  
  }
  
  @media screen and (min-width: 1200px) {

    /*xl*/
    /*   
    h1 {
        font-family: vazirmatn-ExtraBold !important;
        font-size: 3.6rem;
        line-height: 5.1rem;
        font-weight: 700;
        letter-spacing: -1.4px;
    }
  
    h2 {
        font-family: vazirmatn-bold;
        font-size: 2.4rem;
        line-height: 3.9rem;
        font-weight: 700;
        letter-spacing: -1.25px;
    }
  
    h3 {
        font-family: vazirmatn-SemiBold;
        font-size: 1.9rem;
        line-height: 3.7rem;
        font-weight: 100;
        letter-spacing: -0.25px;
        margin-block-end: 0;
        margin-block-start: 0;
    }
  
    h4 {
        font-family: vazirmatn-SemiBold;
        font-size: 1.8rem;
        line-height: 3.4rem;
        font-weight: 700;
        letter-spacing: -.05px;
  
    }
  
    h5 {
        font-family: vazirmatn-medium;
        font-size: 1.65rem;
        line-height: 1.9rem;
        font-weight: 700;
        letter-spacing: -.04px;
  
    }
  
    h6 {
        font-family: vazirmatn-medium;
        font-size: 1.45rem;
        line-height: 1.8rem;
        font-weight: 700;
        letter-spacing: -.0375px;
    }
  
    a {
        font-family: vazirmatn-medium;
        font-size: 1.3rem;
        line-height: 2.7rem;
        font-weight: 300;
        letter-spacing: -.16px;
  
    }
  
    td {
        font-family: vazirmatn-Regular;
        font-size: 1.1rem;
        line-height: 2.2rem;
        font-weight: 400;
        text-align: justify;
        letter-spacing: -.14px;
    }
  
    p {
        font-family: vazirmatn-Regular;
        font-size: 1.1rem;
        line-height: 2.2rem;
        font-weight: 400;
        text-align: justify;
        letter-spacing: -.14px;
        margin-block-end: 0;
        margin-block-start: 0;
    }
  
    div {
        font-family: vazirmatn-Light !important;
        font-size: 1.45rem !important;
        line-height: 2.6rem;
        font-weight: 500;
        letter-spacing: -.0087rem;
    }
  
    button {
        font-family: vazirmatn-Light !important;
        font-size: 1.2rem !important;
        
        line-height: 2.2rem !important;
        
        font-weight: 500;
        letter-spacing: -.0087rem;
    }
    */
  } 
  
  
  /*.font-x {*/
  /*    font-size: 1rem;*/
  /*}*/
  
  /*.font-2x {*/
  /*    font-size: 1.2rem;*/
  /*}*/
  
  /*.font-3x {*/
  /*    font-size: 1.4rem;*/
  /*}*/
  
  /*.font-4x {*/
  /*    font-size: 1.6rem;*/
  /*}*/
  
  /*.font-5x {*/
  /*    font-size: 1.8rem;*/
  /*}*/